<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <h2>Civil Service Eligibility</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-btn color="primary" class="mr-4 text--white" @click="openDialog()"
          >Add Info...</v-btn
        >
      </v-col>
      <v-col col="12" sm="12" md="12">
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item="row">
            <tr>
              <td nowrap>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="updates(row.item)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="deletes(row.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>{{ row.item.Career }}</td>
              <td>{{ row.item.Rating }}</td>
              <td>{{ row.item.DOE }}</td>
              <td>{{ row.item.POE }}</td>
              <td>{{ row.item.LicenseNo }}</td>
              <td>{{ row.item.Validity }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Information</span>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                  

                    <v-textarea
                    v-model="Career"
                      label="Career Service"
                      :rules="[
                        vv.init(this.Career),
                        vv.required(),
                        vv.maxlen(255),
                      ]"
                      auto-grow
                      outlined
                      dense
                      rows="1"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="Rating"
                      label="Rating"
                      :rules="[
                        vv.init(this.Rating),
                        vv.required(),
                        vv.maxlen(45),
                      ]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="DOE"
                      label="Date of Exam"
                      type="date"
                      :rules="[vv.init(this.DOE), vv.requiredDate()]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="POE"
                      label="Place of Exam"
                      :rules="[vv.init(this.POE), vv.required(), vv.maxlen(45)]"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="LicenseNo"
                      label="License No."
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="Validity"
                      label="Validity"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="savechanges()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import vldn from "@/js/validation";
export default {
  name: "Civil",
  data: () => ({
    valid: false,
    formTitle: "",
    dialog: false,
    headers: [
      {
        text: "Action",
        align: "start",
        sortable: false,
        value: "action",
      },
      { text: "Career Service", sortable: false, value: "Career" },
      { text: "Rating", sortable: false, value: "Rating" },
      { text: "Date of Examination", sortable: false, value: "DOE" },
      { text: "Place of Examination", sortable: false, value: "POE" },
      { text: "License No.", sortable: false, value: "LicenseNo" },
      { text: "Validity", sortable: false, value: "Validity" },
    ],
    desserts: [],
    ID: 0,
    Career: "",
    Rating: "",
    DOE: "",
    POE: "",
    LicenseNo: "",
    Validity: "",
    vv: vldn.methods,
  }),
  componets: {
    vldn,
  },
  mounted() {
    this.$api.methods.init();
    this.getelig();
  },
  methods: {
    openDialog() {
      this.ID = 0;

      this.dialog = true;
    },
    savechanges() {
   
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "pds/updateeligibility",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            ID: this.ID,
            Career: this.Career,
            Rating: this.Rating,
            DOE: this.DOE,
            POE: this.POE,
            LicenseNo: this.LicenseNo,
            Validity: this.Validity,
          },
        })
          .then((response) => {
            if (response) {
              this.getelig();
              this.ID = 0;
              this.Career = "";
              this.Rating = "";
              this.DOE = "";
              this.POE = "";
              this.LicenseNo = "";
              this.Validity = "";
              this.dialog = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
    deletes(item) {
      this.$axios({
        method: "post",
        url: process.env.VUE_APP_BASE_URL + "pds/deleteeligibility",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
        data: {
          ID: item.ID,
        },
      })
        .then((response) => {
          if (response) {
            this.getelig();
            this.ID = 0;
            this.Career = "";
            this.Rating = "";
            this.DOE = "";
            this.POE = "";
            this.LicenseNo = "";
            this.Validity = "";
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    updates(item) {
      this.ID = item.ID;
      this.Career = item.Career;
      this.Rating = item.Rating;
      this.DOE = item.DOE;
      this.POE = item.POE;
      this.LicenseNo = item.LicenseNo;
      this.Validity = item.Validity;
      this.dialog = true;
    },
    getelig() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/eligibility",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.desserts = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
  },
};
</script>
<style>
.container {
  max-width: 80%;
}
</style>
